<!-- remark-edit -->
<template>
  <el-dialog
    class="remark-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    append-to-body
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="remark-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="主播名称:">
        <div>{{ signAnchorName }}</div>
      </el-form-item>

      <el-form-item label="备注内容:" prop="content">
        <el-input
          v-model.trim="model.content"
          type="textarea"
          :rows="6"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="remark-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import {
    getSignRemark,
    addSignRemark,
    updateSignRemark,
  } from '../../../../../api/anchor/sign';

  export default {
    name: 'remark-edit',
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 当前编辑的签约备注对象
        remark: null,
        model: null,
        rules: {
          content: [{ required: true, message: '请填写', trigger: 'change' }],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState('anchor/sign', ['signId', 'signRemarkId']),
      ...mapGetters('anchor/sign', ['signAnchorName']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.signRemarkId;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}签约备注`;
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const { content = '' } = data || {};

        this.model = { content };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.signRemarkId,
          signId: this.signId,
        };
        const method = this.isEdit ? updateSignRemark : addSignRemark;
        const res = await method(params);

        return !!res;
      },
      // 获取签约备注对象详情
      async getSignRemark() {
        const res = await getSignRemark(this.signRemarkId);

        if (!res) return false;

        this.remark = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getSignRemark();

        this.loading = false;

        if (!success) return;

        this.initModel(this.remark);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.remark = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .remark-edit {
    .remark-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
</style>
